export function getConfig() {
  const audience =
    window?.auth0Config?.audience && window.auth0Config.audience !== "YOUR_API_IDENTIFIER"
      ? window.auth0Config.audience
      : null;

  return {
    domain: window.auth0Config?.domain,
    clientId: window.auth0Config?.clientId,
    ...(audience ? { audience } : null),
    apiOrigin: window.location.href.includes("localhost") ? "http://localhost:3001/api" : audience, // hacky workaround for now
  };
}
